/*!
 * Start Bootstrap - Freelancer Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */
body {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background-color: #F4F4F4;
}

p {
    font-size: 18px;
}

    p.small {
        font-size: 16px;
    }

    p.medium {
        font-size: 20px;
    }

    p.big {
        font-size: 24px;
    }
    p.title{
      text-align: center;
      text-decoration: underline;
    }

a,
a:hover,
a:focus,
a:active,
a.active {
    outline: 0;
    color: grey;
    text-decoration: underline;
}

.navbar a,
      a:hover,
      a:focus,
      a:active,
      a.active {
    outline: 0;
    color: grey;
        text-decoration: none;
}

.extra a,
       a:hover,
       a:focus,
       a:active,
       a.active {
    outline: 0;
    color: red;
    text-decoration: underline;
}

h1 {
    /*text-transform: uppercase;*/
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-size: 28px;
}

h2,
h3,
h4{
    /*text-transform: uppercase;*/
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
}

h5,
h6 {
    text-transform: uppercase;
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
}

@media(max-width:767px) {
    h1 {
      font-size: 26px;
    }

    h2,
    h3{
        font-size: 24px;
    }

    h4{
        font-size: 18px;
    }

    h5,
    h6 {
        font-size: 18px;
    }

    p {
        font-size: 14px;
    }

        p.small {
            font-size: 12px;
        }

        p.medium {
            font-size: 18px;
        }

        p.big {
            font-size: 24px;
        }
}

/* ****** IMG ****** */
.img-centered {
    margin: 0 auto;
}

.instructor_thumbnail {
	display: inline-flex;
    position: relative;
    width: 200px;
    height: 280px;
}

@font-face {
    font-family: sports_night;
    src: url('fonts/SF Sports Night.ttf'), url('fonts/SF_Sports_Night.eot'); /* IE9 */
}

/**[id]:before {
    display: block;
    content: " ";
    margin-top: -67px;
    height: 67px;
    visibility: hidden;
}*/

/* ****** PANEL ****** */
.panel-heading .rci-toggle:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings'; /* essential for enabling glyphicon */
    content: "\e113"; /* adjust as needed, taken from bootstrap.css */
    float: right; /* adjust as needed */
    color: white; /* adjust as needed */
}

.panel-heading .rci-toggle.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\e114"; /* adjust as needed, taken from bootstrap.css */
}

.panel .panel-heading {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    border: 0 0 0 0;
    border-color: transparent;
    background-color: transparent;
}

.panel .panel-body {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    border: 0 0 0 0;
    border-color: transparent;
    background-color: transparent;
}

.panel .panel-footer {
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    border: 0 0 0 0;
    border-color: transparent;
    background-color: transparent;
}

.hero {
    background-color: white;
}


/* ****** JUMBOTRON ****** */

.jumbotron {
    background-color: white;
    padding: 50px 20px 20px 0px;
    font-family: Montserrat, sans-serif;
}

/* ****** WATERMARK ****** */

.watermark {
    opacity: 0.6;
    position: absolute;
    width: 25%;
    height: 25%;
    /*top: 100%;*/
}

@media(min-width:767px) {
    .watermark {
        opacity: 0.6;
        position: absolute;
        width: 25%;
        height: 25%;
        /*left: 400px;
        top: 580px;*/
        left: 20%;
        top: -35%;
    }
}

/* ****** LKB DEFINITIONS ****** */

#malware {
    float: left;
    padding: 10px 0 0 70px;
}

.lk_name_b {
    margin: 30px 0 0;
    font-family: 'sports_night';
    font-size: 2.5em;
}

.lk_name_m {
    margin: 30px 0 0;
    font-family: 'sports_night';
    font-size: 1.5em;
}

.lk_name_n {
    margin: 30px 0 0;
    font-family: 'sports_night';
    font-size: 1.2em;
}

.lk_name_s {
    margin: 30px 0 0;
    font-family: 'sports_night';
    font-size: 1.0em;
    color: black;
}

.lk_phrase {
    margin: 0;
    font-size: 0.9em;
    font-weight: normal;
}

#lk_store {
    padding: 30px 0 30px 20px;
}

    #lk_store p {
        margin: 0;
        text-align: center;
    }


.lk_red_b {
    margin: 30px 0 0;
    font-family: 'sports_night';
    font-size: 2.5em;
    color: #BA0000;
}

.lk_red_m {
    margin: 30px 0 0;
    font-family: 'sports_night';
    font-size: 1.5em;
    color: #BA0000;
}

.lk_red_n {
    margin: 30px 0 0;
    font-family: 'sports_night';
    font-size: 1.2em;
    color: #BA0000;
}

.lk_red_s {
    margin: 30px 0 0;
    font-family: 'sports_night';
    font-size: 1.0em;
    color: #BA0000;
}

.left_float {
    float: left;
}

/*Classes*/
.font_type {
    font-family: 'sports_night';
}

.left_float {
    float: left;
}

.normal_font {
    font-weight: normal;
}

.italic_font {
    font-style: italic;
}

.bold_font {
    font-weight: bold;
}

.grey_type {
    color: #CCC;
}

.red_type {
    color: #BA0000;
}

.black_background {
    background-color: #000;
}

.no_margin {
    margin: 0;
}

.medium_font {
    font-size: 0.8em;
}

.normal_links {
    background-color: #000;
    color: #FFF;
    font-weight: bold;
    font-style: italic;
    text-decoration: none;
}

    .normal_links:hover {
        background-color: #BA0000;
        color: #FFF;
    }

.pt_image {
    margin: 10px;
    float: right;
    border: 2px solid #F4F4F4;
}

.logo {
    margin-top: 10px;
    padding: 10px;
    float: left;
}

.image_boxes {
    float: left;
    width: 160px;
    height: 140px;
}

.store_images {
    border: 1px solid #EBEBEB;
}

.main_content {
    padding: 10px 20px;
    float: left;
    max-width: 300px;
}

    .main_content h4 {
        margin: 5px 0;
    }

    .main_content p {
        margin: 0;
        font-size: 0.8em;
    }

.lkbx_sections {
    margin: 10px 0 0 20px;
    float: left;
    max-width: 208px;
}

    .lkbx_sections h4 {
        margin: 5px 0;
    }

    .lkbx_sections a {
        padding: 3px 10px;
        background-color: #000;
        color: #FFF;
        font-size: 0.8em;
        font-weight: bold;
        font-style: italic;
        text-decoration: none;
    }

        .lkbx_sections a:hover {
            background-color: #BA0000;
            color: #FFF;
        }

.instructors_profile {
    margin-top: 15px;
}

.instructors_image {
    padding-right: 20px;
    float: left;
}

.instructors_text {
    margin-top: 10px;
    float: left;
    width: 600px;
}

.instructors_profile h5 {
    margin: 2px 0;
}

.bytomic_logo {
    margin-top: 40px;
}

.equipt_images {
    margin: 20px;
    float: left;
    text-align: center;
}

#schools_list ul {
    margin: 30px 0;
    padding: 0;
}

#schools_list ul li {
    display: block;
}

#friends_links ul {
    margin: 20px 0;
    padding: 0;
}

#friends_links ul li {
    display: block;
    padding: 2px 0;
}

#friends_links ul li a {
    color: #000;
}

#friends_links ul li a:hover {
    color: #BA0000;
}

#socials {
    margin: -25px 30px 0 0;
    float: right;
}

#map_canvas {
    margin: 10px;
    padding: 0;
    width: 50%;
    float: left;
    height: 300px;
    border: 1px solid #000;
}

#equiptment {
    margin-top: 30px;
}

    #equiptment p {
        margin: 0;
    }

#apDiv1 {
    position: absolute;
    width: 251px;
    height: 363px;
    z-index: 1;
    left: 222px;
    top: 180px;
}

.style1 {
    color: #B60000;
    font-style: italic;
    font-weight: bold;
}

.style2 {
    color: #B60000;
    font-weight: bold;
}

.style4 {
    color: #B60000;
}

.style7 {
    color: #b60000;
    font-style: italic;
    font-weight: bold;
}

.style8 {
    font-size: 18px;
}

.style9 {
    font-size: 14px;
    color: #000000;
}

.style11 {
    font-style: italic;
}

.style12 {
    font-size: 24px;
}

#lkbx-img {
    margin-left: 20px;
    margin-right: 20px;
}

#lkbx-img-r {
    margin-left: 20px;
    margin-right: 0px;
}

#lkbx-img-l {
    margin-left: 0px;
    margin-right: 20px;
}

#lkbx-img-b {
    margin-bottom: 0px;
}

#lkbx-img-bs {
    margin-bottom: 10px;
}

#lkbx-img-rb {
    margin-left: 20px;
    margin-right: 0px;
    margin-bottom: 20px;
}
.capbox {
	background-color: lightblue;
	border: blue 0px solid;
	border-width: 0px 12px 0px 0px;
	display: inline-block;
	*display: inline; zoom: 1; /* FOR IE7-8 */
	padding: 8px 40px 8px 8px;
	}

.capbox-inner {
	font: bold 11px arial, sans-serif;
	color: #000000;
	background-color: #DBF3BA;
	margin: 5px auto 0px auto;
	padding: 3px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	}

#CaptchaDiv {
	font: bold 17px verdana, arial, sans-serif;
	font-style: italic;
	color: #000000;
	background-color: #FFFFFF;
	padding: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
	border-radius: 4px;
	}

#CaptchaInput { margin: 1px 0px 1px 0px; width: 135px; }

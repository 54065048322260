.pagefind-ui__result.svelte-j9e30.svelte-j9e30 {
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  gap: min(calc(40px * var(--pagefind-ui-scale)), 3%);
  padding: calc(10px * var(--pagefind-ui-scale)) 0
    calc(20px * var(--pagefind-ui-scale));
  border-top: solid var(--pagefind-ui-border-width) var(--pagefind-ui-border);
}
.pagefind-ui__result.svelte-j9e30.svelte-j9e30:last-of-type {
  border-bottom: solid var(--pagefind-ui-border-width) var(--pagefind-ui-border);
}
.pagefind-ui__result-thumb.svelte-j9e30.svelte-j9e30 {
  width: min(30%, calc((30% - (100px * var(--pagefind-ui-scale))) * 100000));
  max-width: calc(120px * var(--pagefind-ui-scale));
  margin-top: calc(10px * var(--pagefind-ui-scale));
  aspect-ratio: var(--pagefind-ui-image-box-ratio);
  position: relative;
}
.pagefind-ui__result-image.svelte-j9e30.svelte-j9e30 {
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  font-size: 0;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: var(--pagefind-ui-image-border-radius);
}
.pagefind-ui__result-inner.svelte-j9e30.svelte-j9e30 {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: calc(10px * var(--pagefind-ui-scale));
}
.pagefind-ui__result-title.svelte-j9e30.svelte-j9e30 {
  display: inline-block;
  font-weight: 700;
  font-size: calc(21px * var(--pagefind-ui-scale));
  margin-top: 0;
  margin-bottom: 0;
}
.pagefind-ui__result-title.svelte-j9e30 .pagefind-ui__result-link.svelte-j9e30 {
  color: var(--pagefind-ui-text);
  text-decoration: none;
}
.pagefind-ui__result-title.svelte-j9e30
  .pagefind-ui__result-link.svelte-j9e30:hover {
  text-decoration: underline;
}
.pagefind-ui__result-excerpt.svelte-j9e30.svelte-j9e30 {
  display: inline-block;
  font-weight: 400;
  font-size: calc(16px * var(--pagefind-ui-scale));
  margin-top: calc(4px * var(--pagefind-ui-scale));
  margin-bottom: 0;
  min-width: calc(250px * var(--pagefind-ui-scale));
}
.pagefind-ui__loading.svelte-j9e30.svelte-j9e30 {
  color: var(--pagefind-ui-text);
  background-color: var(--pagefind-ui-text);
  border-radius: var(--pagefind-ui-border-radius);
  opacity: 0.1;
  pointer-events: none;
}
.pagefind-ui__result-tags.svelte-j9e30.svelte-j9e30 {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: calc(20px * var(--pagefind-ui-scale));
  flex-wrap: wrap;
  margin-top: calc(20px * var(--pagefind-ui-scale));
}
.pagefind-ui__result-tag.svelte-j9e30.svelte-j9e30 {
  padding: calc(4px * var(--pagefind-ui-scale))
    calc(8px * var(--pagefind-ui-scale));
  font-size: calc(14px * var(--pagefind-ui-scale));
  border-radius: var(--pagefind-ui-border-radius);
  background-color: var(--pagefind-ui-tag);
}
.pagefind-ui__result.svelte-4xnkmf.svelte-4xnkmf {
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  gap: min(calc(40px * var(--pagefind-ui-scale)), 3%);
  padding: calc(30px * var(--pagefind-ui-scale)) 0
    calc(40px * var(--pagefind-ui-scale));
  border-top: solid var(--pagefind-ui-border-width) var(--pagefind-ui-border);
}
.pagefind-ui__result.svelte-4xnkmf.svelte-4xnkmf:last-of-type {
  border-bottom: solid var(--pagefind-ui-border-width) var(--pagefind-ui-border);
}
.pagefind-ui__result-nested.svelte-4xnkmf.svelte-4xnkmf {
  display: flex;
  flex-direction: column;
  padding-left: calc(20px * var(--pagefind-ui-scale));
}
.pagefind-ui__result-nested.svelte-4xnkmf.svelte-4xnkmf:first-of-type {
  padding-top: calc(10px * var(--pagefind-ui-scale));
}
.pagefind-ui__result-nested.svelte-4xnkmf
  .pagefind-ui__result-link.svelte-4xnkmf {
  font-size: 0.9em;
  position: relative;
}
.pagefind-ui__result-nested.svelte-4xnkmf
  .pagefind-ui__result-link.svelte-4xnkmf:before {
  content: "\2937  ";
  position: absolute;
  top: 0;
  right: calc(100% + 0.1em);
}
.pagefind-ui__result-thumb.svelte-4xnkmf.svelte-4xnkmf {
  width: min(30%, calc((30% - (100px * var(--pagefind-ui-scale))) * 100000));
  max-width: calc(120px * var(--pagefind-ui-scale));
  margin-top: calc(10px * var(--pagefind-ui-scale));
  aspect-ratio: var(--pagefind-ui-image-box-ratio);
  position: relative;
}
.pagefind-ui__result-image.svelte-4xnkmf.svelte-4xnkmf {
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  font-size: 0;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: var(--pagefind-ui-image-border-radius);
}
.pagefind-ui__result-inner.svelte-4xnkmf.svelte-4xnkmf {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: calc(10px * var(--pagefind-ui-scale));
}
.pagefind-ui__result-title.svelte-4xnkmf.svelte-4xnkmf {
  display: inline-block;
  font-weight: 700;
  font-size: calc(21px * var(--pagefind-ui-scale));
  margin-top: 0;
  margin-bottom: 0;
}
.pagefind-ui__result-title.svelte-4xnkmf
  .pagefind-ui__result-link.svelte-4xnkmf {
  color: var(--pagefind-ui-text);
  text-decoration: none;
}
.pagefind-ui__result-title.svelte-4xnkmf
  .pagefind-ui__result-link.svelte-4xnkmf:hover {
  text-decoration: underline;
}
.pagefind-ui__result-excerpt.svelte-4xnkmf.svelte-4xnkmf {
  display: inline-block;
  font-weight: 400;
  font-size: calc(16px * var(--pagefind-ui-scale));
  margin-top: calc(4px * var(--pagefind-ui-scale));
  margin-bottom: 0;
  min-width: calc(250px * var(--pagefind-ui-scale));
}
.pagefind-ui__loading.svelte-4xnkmf.svelte-4xnkmf {
  color: var(--pagefind-ui-text);
  background-color: var(--pagefind-ui-text);
  border-radius: var(--pagefind-ui-border-radius);
  opacity: 0.1;
  pointer-events: none;
}
.pagefind-ui__result-tags.svelte-4xnkmf.svelte-4xnkmf {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: calc(20px * var(--pagefind-ui-scale));
  flex-wrap: wrap;
  margin-top: calc(20px * var(--pagefind-ui-scale));
}
.pagefind-ui__result-tag.svelte-4xnkmf.svelte-4xnkmf {
  padding: calc(4px * var(--pagefind-ui-scale))
    calc(8px * var(--pagefind-ui-scale));
  font-size: calc(14px * var(--pagefind-ui-scale));
  border-radius: var(--pagefind-ui-border-radius);
  background-color: var(--pagefind-ui-tag);
}
legend.svelte-1v2r7ls.svelte-1v2r7ls {
  position: absolute;
  clip: rect(0 0 0 0);
}
.pagefind-ui__filter-panel.svelte-1v2r7ls.svelte-1v2r7ls {
  min-width: min(calc(260px * var(--pagefind-ui-scale)), 100%);
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: calc(20px * var(--pagefind-ui-scale));
}
.pagefind-ui__filter-group.svelte-1v2r7ls.svelte-1v2r7ls {
  border: 0;
  padding: 0;
}
.pagefind-ui__filter-block.svelte-1v2r7ls.svelte-1v2r7ls {
  padding: 0;
  display: block;
  border-bottom: solid calc(2px * var(--pagefind-ui-scale))
    var(--pagefind-ui-border);
  padding: calc(20px * var(--pagefind-ui-scale)) 0;
}
.pagefind-ui__filter-name.svelte-1v2r7ls.svelte-1v2r7ls {
  font-size: calc(16px * var(--pagefind-ui-scale));
  position: relative;
  display: flex;
  align-items: center;
  list-style: none;
  font-weight: 700;
  cursor: pointer;
  height: calc(24px * var(--pagefind-ui-scale));
}
.pagefind-ui__filter-name.svelte-1v2r7ls.svelte-1v2r7ls::-webkit-details-marker {
  display: none;
}
.pagefind-ui__filter-name.svelte-1v2r7ls.svelte-1v2r7ls:after {
  position: absolute;
  content: "";
  right: calc(6px * var(--pagefind-ui-scale));
  top: 50%;
  width: calc(8px * var(--pagefind-ui-scale));
  height: calc(8px * var(--pagefind-ui-scale));
  border: solid calc(2px * var(--pagefind-ui-scale)) currentColor;
  border-right: 0;
  border-top: 0;
  transform: translateY(-70%) rotate(-45deg);
}
.pagefind-ui__filter-block[open].svelte-1v2r7ls
  .pagefind-ui__filter-name.svelte-1v2r7ls:after {
  transform: translateY(-70%) rotate(-225deg);
}
.pagefind-ui__filter-group.svelte-1v2r7ls.svelte-1v2r7ls {
  display: flex;
  flex-direction: column;
  gap: calc(20px * var(--pagefind-ui-scale));
  padding-top: calc(30px * var(--pagefind-ui-scale));
}
.pagefind-ui__filter-value.svelte-1v2r7ls.svelte-1v2r7ls {
  position: relative;
  display: flex;
  align-items: center;
  gap: calc(8px * var(--pagefind-ui-scale));
}
.pagefind-ui__filter-value.svelte-1v2r7ls.svelte-1v2r7ls:before {
  position: absolute;
  content: "";
  top: 50%;
  left: calc(8px * var(--pagefind-ui-scale));
  width: 0px;
  height: 0px;
  border: solid 1px #fff;
  opacity: 0;
  transform: translate(
      calc(4.5px * var(--pagefind-ui-scale) * -1),
      calc(0.8px * var(--pagefind-ui-scale))
    )
    skew(-5deg) rotate(-45deg);
  transform-origin: top left;
  border-top: 0;
  border-right: 0;
  pointer-events: none;
}
.pagefind-ui__filter-value.pagefind-ui__filter-value--checked.svelte-1v2r7ls.svelte-1v2r7ls:before {
  opacity: 1;
  width: calc(9px * var(--pagefind-ui-scale));
  height: calc(4px * var(--pagefind-ui-scale));
  transition: width 0.1s ease-out 0.1s, height 0.1s ease-in;
}
.pagefind-ui__filter-checkbox.svelte-1v2r7ls.svelte-1v2r7ls {
  margin: 0;
  width: calc(16px * var(--pagefind-ui-scale));
  height: calc(16px * var(--pagefind-ui-scale));
  border: solid 1px var(--pagefind-ui-border);
  appearance: none;
  -webkit-appearance: none;
  border-radius: calc(var(--pagefind-ui-border-radius) / 2);
  background-color: var(--pagefind-ui-background);
  cursor: pointer;
}
.pagefind-ui__filter-checkbox.svelte-1v2r7ls.svelte-1v2r7ls:checked {
  background-color: var(--pagefind-ui-primary);
  border: solid 1px var(--pagefind-ui-primary);
}
.pagefind-ui__filter-label.svelte-1v2r7ls.svelte-1v2r7ls {
  cursor: pointer;
  font-size: calc(16px * var(--pagefind-ui-scale));
  font-weight: 400;
}
.pagefind-ui--reset
  *:where(:not(html, iframe, canvas, img, svg, video):not(svg *, symbol *)) {
  all: unset;
  display: revert;
  outline: revert;
}
.pagefind-ui--reset *,
.pagefind-ui--reset *:before,
.pagefind-ui--reset *:after {
  box-sizing: border-box;
}
.pagefind-ui--reset a,
.pagefind-ui--reset button {
  cursor: revert;
}
.pagefind-ui--reset ol,
.pagefind-ui--reset ul,
.pagefind-ui--reset menu {
  list-style: none;
}
.pagefind-ui--reset img {
  max-width: 100%;
}
.pagefind-ui--reset table {
  border-collapse: collapse;
}
.pagefind-ui--reset input,
.pagefind-ui--reset textarea {
  -webkit-user-select: auto;
}
.pagefind-ui--reset textarea {
  white-space: revert;
}
.pagefind-ui--reset meter {
  -webkit-appearance: revert;
  appearance: revert;
}
.pagefind-ui--reset ::placeholder {
  color: unset;
}
.pagefind-ui--reset :where([hidden]) {
  display: none;
}
.pagefind-ui--reset :where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  -webkit-line-break: after-white-space;
  -webkit-user-select: auto;
}
.pagefind-ui--reset :where([draggable="true"]) {
  -webkit-user-drag: element;
}
.pagefind-ui--reset mark {
  all: revert;
}
:root {
  --pagefind-ui-scale: 0.8;
  --pagefind-ui-primary: #393939;
  --pagefind-ui-text: #393939;
  --pagefind-ui-background: #ffffff;
  --pagefind-ui-border: grey;
   /* #eeeeee; */
  --pagefind-ui-tag: #eeeeee;
  --pagefind-ui-border-width: 2px;
  --pagefind-ui-border-radius: 8px;
  --pagefind-ui-image-border-radius: 8px;
  --pagefind-ui-image-box-ratio: 3 / 2;
  --pagefind-ui-font: system, -apple-system, "BlinkMacSystemFont",
    ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue",
    "Lucida Grande", "Ubuntu", "arial", sans-serif;
}
.pagefind-ui.svelte-e9gkc3 {
  /* position: fixed; */
  margin-top: 120px;
  width: 100%;
  color: var(--pagefind-ui-text);
  font-family: var(--pagefind-ui-font);
}
@media (max-width: 1200px) {
  .pagefind-ui.svelte-e9gkc3 {
    margin-top: 120px;
  }
}

@media (max-width: 1199px) {
  .pagefind-ui.svelte-e9gkc3 {
    margin-top: 150px;
  }
}

@media (max-width: 991px) {
  .pagefind-ui.svelte-e9gkc3 {
    margin-top: 100px;
  }
}

@media (max-width: 768px) {
  .pagefind-ui.svelte-e9gkc3 {
    margin-top: 50px;
  }
}

@media (max-width: 600px) {
  .pagefind-ui.svelte-e9gkc3 {
    margin-top: 45px;
  }
}
.pagefind-ui__hidden.svelte-e9gkc3 {
  display: none !important;
}
.pagefind-ui__suppressed.svelte-e9gkc3 {
  opacity: 0;
  pointer-events: none;
}
.pagefind-ui__form.svelte-e9gkc3 {
  position: relative;
}
.pagefind-ui__form.svelte-e9gkc3:before {
  background-color: var(--pagefind-ui-text);
  width: calc(18px * var(--pagefind-ui-scale));
  height: calc(18px * var(--pagefind-ui-scale));
  top: calc(23px * var(--pagefind-ui-scale));
  left: calc(20px * var(--pagefind-ui-scale));
  content: "";
  position: absolute;
  display: block;
  opacity: 0.7;
  -webkit-mask-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.7549 11.255H11.9649L11.6849 10.985C12.6649 9.845 13.2549 8.365 13.2549 6.755C13.2549 3.165 10.3449 0.255005 6.75488 0.255005C3.16488 0.255005 0.254883 3.165 0.254883 6.755C0.254883 10.345 3.16488 13.255 6.75488 13.255C8.36488 13.255 9.84488 12.665 10.9849 11.685L11.2549 11.965V12.755L16.2549 17.745L17.7449 16.255L12.7549 11.255ZM6.75488 11.255C4.26488 11.255 2.25488 9.245 2.25488 6.755C2.25488 4.26501 4.26488 2.255 6.75488 2.255C9.24488 2.255 11.2549 4.26501 11.2549 6.755C11.2549 9.245 9.24488 11.255 6.75488 11.255Z' fill='%23000000'/%3E%3C/svg%3E%0A");
  mask-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.7549 11.255H11.9649L11.6849 10.985C12.6649 9.845 13.2549 8.365 13.2549 6.755C13.2549 3.165 10.3449 0.255005 6.75488 0.255005C3.16488 0.255005 0.254883 3.165 0.254883 6.755C0.254883 10.345 3.16488 13.255 6.75488 13.255C8.36488 13.255 9.84488 12.665 10.9849 11.685L11.2549 11.965V12.755L16.2549 17.745L17.7449 16.255L12.7549 11.255ZM6.75488 11.255C4.26488 11.255 2.25488 9.245 2.25488 6.755C2.25488 4.26501 4.26488 2.255 6.75488 2.255C9.24488 2.255 11.2549 4.26501 11.2549 6.755C11.2549 9.245 9.24488 11.255 6.75488 11.255Z' fill='%23000000'/%3E%3C/svg%3E%0A");
  -webkit-mask-size: 100%;
  mask-size: 100%;
  z-index: 9;
  pointer-events: none;
}
.pagefind-ui__search-input.svelte-e9gkc3 {
  height: calc(64px * var(--pagefind-ui-scale));
  padding: 0 calc(70px * var(--pagefind-ui-scale)) 0
    calc(54px * var(--pagefind-ui-scale));
  background-color: var(--pagefind-ui-background);
  border: var(--pagefind-ui-border-width) solid var(--pagefind-ui-border);
  border-radius: var(--pagefind-ui-border-radius);
  font-size: calc(21px * var(--pagefind-ui-scale));
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-weight: 700;
}
.pagefind-ui__search-input.svelte-e9gkc3::placeholder {
  opacity: 0.2;
}
.pagefind-ui__search-clear.svelte-e9gkc3 {
  position: absolute;
  top: calc(3px * var(--pagefind-ui-scale));
  right: calc(3px * var(--pagefind-ui-scale));
  height: calc(50px * var(--pagefind-ui-scale));
  padding: 0 calc(15px * var(--pagefind-ui-scale)) 0
    calc(1px * var(--pagefind-ui-scale));
  color: var(--pagefind-ui-text);
  font-size: calc(20px * var(--pagefind-ui-scale));
  cursor: pointer;
  background-color: var(--pagefind-ui-background);
  border-radius: var(--pagefind-ui-border-radius);
}
.pagefind-ui__drawer.svelte-e9gkc3 {
  gap: calc(60px * var(--pagefind-ui-scale));
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 30px;
}
.pagefind-ui__results-area.svelte-e9gkc3 {
  min-width: min(calc(400px * var(--pagefind-ui-scale)), 100%);
  flex: 1000;
  margin-top: calc(20px * var(--pagefind-ui-scale));
}
.pagefind-ui__results.svelte-e9gkc3 {
  padding: 0;
}
.pagefind-ui__message.svelte-e9gkc3 {
  box-sizing: content-box;
  font-size: calc(16px * var(--pagefind-ui-scale));
  height: calc(24px * var(--pagefind-ui-scale));
  padding: calc(20px * var(--pagefind-ui-scale)) 0;
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-top: 0;
}
.pagefind-ui__button.svelte-e9gkc3 {
  margin-top: calc(40px * var(--pagefind-ui-scale));
  border: var(--pagefind-ui-border-width) solid var(--pagefind-ui-border);
  border-radius: var(--pagefind-ui-border-radius);
  height: calc(48px * var(--pagefind-ui-scale));
  padding: 0 calc(12px * var(--pagefind-ui-scale));
  font-size: calc(16px * var(--pagefind-ui-scale));
  color: var(--pagefind-ui-primary);
  background: var(--pagefind-ui-background);
  width: 100%;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
}
.pagefind-ui__button.svelte-e9gkc3:hover {
  border-color: var(--pagefind-ui-primary);
  color: var(--pagefind-ui-primary);
  background: var(--pagefind-ui-background);
}
